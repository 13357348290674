import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { MetaDataSection } from "./sections/MetaDataSection"
import { PublishingSection } from "./sections/PublishingSection"
import { IFeatureConfigBrandingModalViewProps } from "./types/configBrandingProps"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { removeLastIdSegment } from "src/helpers/removeLastIdSegment"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { FeatureConfigBrandingStore } from "src/modals/feature-config-branding/store"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"

const View = observer(({ id }: IFeatureConfigBrandingModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(FeatureConfigBrandingStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    const navigate = useNavigate()
    const location = useLocation()
    const parentPath = removeLastIdSegment(location.pathname)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    const isSaveButtonDisabled =
        (store.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        store.isLoading ||
        store.isReadOnly

    thisModal.confirmOnOverlayClick = () => {
        const isDirty = Boolean(
            store.form.getIsDirty() ||
                configurableDynamicFormFieldsStore.form.getIsDirty(),
        )

        if (!isDirty) {
            navigate(parentPath)
        }
        return isDirty
    }

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId)
    }, [id, store, globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await store.handleSubmit(
                configurableDynamicFormFieldsStore.handleValidation,
                configurableDynamicFormFieldsStore.getConfigsApiPayload,
            )

            if (validationError && !Boolean(store.form.error("generic"))) {
                navigate(parentPath)
                globalStore.modals.pop()
            }
        },
        [
            store,
            configurableDynamicFormFieldsStore,
            navigate,
            parentPath,
            globalStore.modals,
        ],
    )

    if (!initialized || store.isLoading) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader path={parentPath}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`global.meta-data`,
                        content: <MetaDataSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`global.configuration`,
                        content: (
                            <ConfigurableDynamicFormFieldsSection
                                configDefinition={store.configDefinition}
                                configs={store.form.get("configs")}
                                isReadOnly={store.isReadOnly}
                                configType={store.form.get("config_type")}
                            />
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`global.publishing-settings`,
                        content: <PublishingSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const FeatureConfigBrandingModalView = (
    props: IFeatureConfigBrandingModalViewProps,
) => (
    <StoreProvider Store={FeatureConfigBrandingStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
