import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { MetaDataSection } from "./sections/MetaDataSection"
import { PublishingSection } from "./sections/PublishingSection"
import { IChatbotConfigurationModalViewProps } from "./types/configAgentProps"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { removeLastIdSegment } from "src/helpers/removeLastIdSegment"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ChatbotConfigurationStore } from "src/modals/chatbot-configuration/store"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"

const View = observer(({ id }: IChatbotConfigurationModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ChatbotConfigurationStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    const navigate = useNavigate()
    const location = useLocation()
    const parentPath = removeLastIdSegment(location.pathname)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    thisModal.confirmOnOverlayClick = () => {
        if (
            !Boolean(store.form.getIsDirty()) &&
            !Boolean(configurableDynamicFormFieldsStore.form.getIsDirty())
        ) {
            navigate(parentPath)
        }
        return (
            store.form.getIsDirty() ||
            configurableDynamicFormFieldsStore.form.getIsDirty()
        )
    }

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId)
    }, [id, store, globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await store.handleSubmit(
                configurableDynamicFormFieldsStore.handleValidation,
                configurableDynamicFormFieldsStore.getConfigsApiPayload,
            )

            if (validationError && !Boolean(store.form.error("generic"))) {
                navigate(parentPath)
                globalStore.modals.pop()
            }
        },
        [
            store,
            configurableDynamicFormFieldsStore.getConfigsApiPayload,
            configurableDynamicFormFieldsStore.handleValidation,
            navigate,
            parentPath,
            globalStore.modals,
        ],
    )

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        (store.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        store.isLoading ||
        store.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader path={parentPath}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.meta-data`,
                        content: <MetaDataSection />,
                    },
                ]}
            />
            <ConfigurableDynamicFormFieldsSection
                configDefinition={store.configDefinition}
                configs={store.form.get("configs") ?? {}}
                isReadOnly={store.isReadOnly}
                configType={store.form.get("config_type")}
            />
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.publishing-settings`,
                        content: <PublishingSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const ChatbotConfigurationModalView = (
    props: IChatbotConfigurationModalViewProps,
) => (
    <StoreProvider Store={ChatbotConfigurationStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
