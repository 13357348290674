import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { IFeatureLists, IContentItemsProps } from "./types/contentItemsProps"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import {
    ConnectorAdminService,
    ContentAdminService,
    EmbedAdminService,
    config_ConfigDefinition,
    config_DynamicConfigMap,
    content_ContentItemEntity,
    InfoCenterAdminService,
    ProductCatalogueAdminService,
    SubscriptionAdminService,
} from "src/api"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { getFeatureDisplayDetails } from "src/lib/feature-tag-description"
import { reportError } from "src/lib/report"
import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { ConfigMode } from "src/types/dynamic-configurable-form-fields/config-mode"

export class ContentItemsModalStore {
    static Context = React.createContext<ContentItemsModalStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    isLoading = false

    private id: number | null = null
    private _featureTargetLists: IFeatureLists[] = []
    private _segmentIds: Array<number> = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"
    private _configDefinition: config_ConfigDefinition = {}
    private _configTypeOptions: IGenericAutoCompleteTextFieldValue[] = []
    private _configMode: ConfigMode = ConfigMode.Single

    form = new FormFields<IContentItemsProps>({
        accessGroupID: DEFAULT_ACCESS_GROUP.id,
        entity: undefined,
        entityID: 0,
        internalName: "",
        segmentIds: [],
        configs: {},
        config_type: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get segmentIDs() {
        return this._segmentIds
    }

    get featureTargetLists() {
        return this._featureTargetLists
    }

    get accessType() {
        return this._accessType
    }

    get isReadOnly() {
        return this.accessType === "READ"
    }
    get accessGroupId() {
        return this._accessGroupId
    }

    get isEditMode() {
        return this.id !== null
    }

    get configDefinition() {
        return this._configDefinition
    }

    get configTypeOptions() {
        return this._configTypeOptions
    }

    get configMode(): ConfigMode {
        return this._configMode
    }
    //#endregion

    //#region setters
    setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    setSegmentIDs(segmentIDs: number[]) {
        this.form.set("segmentIds", segmentIDs)
        this._segmentIds = segmentIDs
    }

    setConfigMode(mode?: ConfigMode) {
        this._configMode = mode ?? ConfigMode.Single
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    private setId(id: number) {
        this.id = id
    }

    private setFeatureTargetLists(featureTargetLists: IFeatureLists[]) {
        this._featureTargetLists = featureTargetLists
    }

    private setAccessType(accessType: string) {
        this._accessType = accessType
    }

    private setConfigDefinition(fields: config_ConfigDefinition) {
        this._configDefinition = fields
    }

    private setConfigTypeOptions(
        options: {
            id: string
            name: string
        }[],
    ) {
        this._configTypeOptions = options
    }
    //#endregion

    //#region operations
    @loads(() => ContentItemsModalStore.LoadingKeys.init)
    async init(
        id: number | undefined,
        accessGroupId: number,
        accessType: string | undefined,
    ) {
        try {
            this.setAccessGroupId(accessGroupId)

            // Get configurable fields definition
            const configDefinition =
                await ContentAdminService.getV1AdminContentConfigDefinition()
            this.setConfigDefinition(configDefinition)
            this.setConfigTypeOptions(
                configDefinition.definition?.map((field) => ({
                    id: field.name ?? "",
                    name: field.name ?? "",
                })) ?? [],
            )
            this.setConfigMode(configDefinition.config_mode as ConfigMode)

            if (id != null) {
                this.setId(id)
                if (accessType !== undefined) {
                    this.setAccessType(accessType)
                }

                const response =
                    await ContentAdminService.getV1AdminContentItem({
                        contentItemId: id,
                    })
                this.form.init({
                    accessGroupID:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    entity: response?.entity ?? undefined,
                    entityID: response?.entity_id ?? -1,
                    internalName: response?.internal_name ?? "",
                    segmentIds: response?.segment_ids ?? [],
                    configs: response.configs ?? {},
                    config_type:
                        response.configs !== undefined
                            ? Object.keys(response.configs).map((name) => ({
                                  id: name,
                                  name,
                              }))
                            : [],
                })

                if (
                    response?.segment_ids !== undefined ||
                    response?.segment_ids !== null
                ) {
                    this.setSegmentIDs(response?.segment_ids ?? [])
                }
                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )
            }
            await this.featureTargetList(accessGroupId)
        } catch (error) {
            reportError(t`edit-information-item-modal.load-data-fail`, error)
            this.setAccessType("READ")
        }

        return Promise.resolve()
    }

    async update(id: number) {
        const { data } = this.form

        await ContentAdminService.putV1AdminContentItem({
            contentItemId: id,
            request: {
                internal_name: data.internalName,
                configs: data.configs,
                entity: data?.entity ?? undefined,
                entity_id: data.entityID ?? "",
            },
        })

        await ContentAdminService.putV1AdminContentItemPublish({
            contentItemId: id,
            request: {
                published_in: this._segmentIds,
            },
        })

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "content-items",
                action: "update",
            },
        })
    }

    async create() {
        const { data } = this.form

        const response = await ContentAdminService.postV1AdminContentItem({
            request: {
                access_group_id: this.accessGroupId,
                entity: data?.entity as content_ContentItemEntity,
                entity_id: data.entityID,
                internal_name: data.internalName,
                configs: data.configs,
            },
        })

        if (
            response.content_item_id !== null ||
            response.content_item_id !== undefined
        ) {
            await ContentAdminService.putV1AdminContentItemPublish({
                contentItemId: response.content_item_id ?? 0,
                request: {
                    published_in: this._segmentIds,
                },
            })
        }

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "content-items",
                action: "create",
            },
        })
    }

    @loads(() => ContentItemsModalStore.LoadingKeys.submit)
    async submit() {
        const { id } = this
        try {
            await this.form.catchErrors(async () => {
                if (id !== null) {
                    await this.update(id)
                } else {
                    await this.create()
                }
            })
        } catch (error) {
            reportError(t`edit-information-item-modal.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    async getAdminNavigationModule(): Promise<IFeatureLists[]> {
        const responseData = await ContentAdminService.getV1AdminContentModule()
        return (
            responseData?.map((data) => {
                return {
                    id: data.feature_flag_id ?? 0,
                    label:
                        data?.module_name !== null
                            ? getFeatureDisplayDetails({
                                  name: data?.module_name,
                              })?.displayName ?? ""
                            : "",
                }
            }) ?? []
        )
    }

    async getAdminEmbedIntegrationList(
        accessGroupId?: number,
    ): Promise<IFeatureLists[]> {
        let responseData = []
        const response =
            await EmbedAdminService.postV1AdminEmbedIntegrationList({
                request: {
                    access_group_id:
                        this.form.get("accessGroupID") > -1
                            ? this.form.get("accessGroupID")
                            : accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
                    page_number: 1,
                    page_size: 1000,
                },
            })
        responseData = response?.integrations ?? []
        return (
            responseData?.map((data) => {
                return { id: data.id ?? 0, label: data?.name ?? "" }
            }) ?? []
        )
    }

    async getConnectorIntegrationList(): Promise<IFeatureLists[]> {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type: "mkb_sensor",
            })
        return (
            (response ?? []).map((data) => {
                return { id: data.integration_id ?? 0, label: data?.name ?? "" }
            }) ?? []
        )
    }

    async getAdminSubscriptionPlanList(): Promise<IFeatureLists[]> {
        const response =
            await SubscriptionAdminService.getV1AdminSubscriptionPlan()
        return (
            (response ?? []).map((data) => {
                return {
                    id: data.id ?? 0,
                    label: data?.name ?? "",
                }
            }) ?? []
        )
    }

    async getAdminCatalogueProductList(
        accessGroupId?: number,
    ): Promise<IFeatureLists[]> {
        const response =
            await ProductCatalogueAdminService.getV1AdminCatalogueProduct({
                accessGroupId:
                    this.form.get("accessGroupID") > -1
                        ? this.form.get("accessGroupID")
                        : accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
                pageNumber: 1,
                pageSize: 1000,
            })
        return (
            (response?.products ?? []).map((data) => {
                return {
                    id: data.product_id ?? 0,
                    label: data?.admin_name ?? "",
                }
            }) ?? []
        )
    }

    async getAdminInformationItemList(
        accessGroupId?: number,
    ): Promise<IFeatureLists[]> {
        const response = await InfoCenterAdminService.infocenterGetItemListPost(
            {
                request: {
                    page_number: 1,
                    page_size: 1000,
                    access_group_id:
                        this.form.get("accessGroupID") > -1
                            ? this.form.get("accessGroupID")
                            : accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
                },
            },
        )

        return (
            (response?.result ?? []).map((data) => {
                return {
                    id: data.information_item_id ?? 0,
                    label: data?.display_name ?? "",
                }
            }) ?? []
        )
    }

    async featureTargetList(accessGroupId?: number) {
        try {
            let featureLists: IFeatureLists[] = []
            this.setFeatureTargetLists([])
            switch (this.form.get("entity")) {
                case "module":
                    featureLists = await this.getAdminNavigationModule()
                    break
                case "embed":
                    featureLists = await this.getAdminEmbedIntegrationList(
                        accessGroupId,
                    )
                    break
                case "integration_connector":
                    featureLists = await this.getConnectorIntegrationList()
                    break
                case "subscription_plan":
                    featureLists = await this.getAdminSubscriptionPlanList()
                    break
                case "catalogue_product":
                    featureLists = await this.getAdminCatalogueProductList(
                        accessGroupId,
                    )
                    break
                case "information_item":
                    featureLists = await this.getAdminInformationItemList(
                        accessGroupId,
                    )
                    break
                default:
                    break
            }
            this.setFeatureTargetLists(featureLists)
        } catch (error) {}
    }
    //#endregion

    //#region helpers
    handleConfigurableDynamicFormFieldsActions = (
        validateConfigurableDynamicFields: () => boolean,
    ) => {
        const configurableDynamicFieldsHasError =
            validateConfigurableDynamicFields !== undefined
                ? validateConfigurableDynamicFields()
                : false

        return configurableDynamicFieldsHasError
    }

    handleSubmit = async (
        accessGroupName: string,
        accessGroupID: number,
        validateConfigurableDynamicFields: () => boolean,
        getConfigsApiPayload: () => config_DynamicConfigMap | undefined,
    ) => {
        this.form.validateRequiredFields([
            { field: "internalName" },
            { field: "entity" },
            { field: "entityID", validate: (val) => val !== 0 },
            {
                field: "config_type",
                validate: (val) =>
                    Array.isArray(val) ? Boolean(val.length) : false,
            },
        ])

        const configurableDynamicFieldsHasError =
            validateConfigurableDynamicFields() === false

        if (
            this.form.hasErrors() === true ||
            configurableDynamicFieldsHasError === true
        ) {
            this.setIsLoading(false)
            return false
        }

        // update form.configs with latest values
        this.form.set("configs", getConfigsApiPayload() ?? {})

        trackModuleEvent("Navigation Items | Save", {
            [MixpanelProperties.ItemName]: this.form.data.internalName,
            [MixpanelProperties.AccessGroupName]: accessGroupName,
            [MixpanelProperties.AccessGroupID]: accessGroupID,
        })

        await this.submit()
        return true
    }
    //#endregion
}
