import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { IFeatureConfigBrandingProps } from "./types/configBrandingProps"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import {
    FeaturesAdminService,
    config_ConfigDefinition,
    config_DynamicConfigMap,
} from "src/api"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"

import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { ConfigMode } from "src/types/dynamic-configurable-form-fields/config-mode"

export class FeatureConfigBrandingStore {
    static Context = React.createContext<FeatureConfigBrandingStore | null>(
        null,
    )

    static LoadingKeys = createLoadingKeys("init", "submit")

    isLoading = false

    private id: string | null = null
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"
    private _configDefinition: config_ConfigDefinition = {}
    private _segmentIds: Array<number> = []
    private _configTypeOptions: IGenericAutoCompleteTextFieldValue[] = []
    private _configMode: ConfigMode = ConfigMode.Single

    form = new FormFields<IFeatureConfigBrandingProps>({
        access_group_id: DEFAULT_ACCESS_GROUP.id,
        access_type: "NONE",
        feature_config_id: undefined,
        name: "",
        segment_ids: [],
        configs: {},
        config_type: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get segmentIDs() {
        return this._segmentIds
    }

    get isReadOnly() {
        return this._accessType === "READ"
    }

    get accessGroupId() {
        return this._accessGroupId
    }

    get configDefinition() {
        return this._configDefinition
    }

    get isEditMode() {
        return this.id !== null
    }

    get configTypeOptions() {
        return this._configTypeOptions
    }

    get configMode() {
        return this._configMode
    }
    //#endregion

    //#region setters
    setAccessGroupId(id: number) {
        this._accessGroupId = id
        this.form.set("access_group_id", id)
    }

    setSegmentIDs(segmentIDs: number[]) {
        this.form.set("segment_ids", segmentIDs)
        this._segmentIds = segmentIDs
    }

    setConfigMode(value?: ConfigMode) {
        this._configMode = value ?? ConfigMode.Single
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    private setId(id: string) {
        this.id = id
    }

    private setAccessType(accessType: string) {
        this._accessType = accessType
    }

    private setConfigDefinition(fields: config_ConfigDefinition) {
        this._configDefinition = fields
    }

    private setConfigTypeOptions(
        options: {
            id: string
            name: string
        }[],
    ) {
        this._configTypeOptions = options
    }
    //#endregion

    //#region operations
    @loads(() => FeatureConfigBrandingStore.LoadingKeys.init)
    async init(id: string | undefined, accessGroupId: number) {
        try {
            this.setAccessGroupId(accessGroupId)

            const configDefinition =
                await FeaturesAdminService.getV2AdminFeatureConfigConfigDefinition(
                    {
                        featureGroup: "branding",
                    },
                )

            this.setConfigDefinition(configDefinition)
            const configTypeOptions =
                configDefinition.definition?.map((field) => ({
                    id: field.name ?? "",
                    name: field.name ?? "",
                })) ?? []
            this.setConfigTypeOptions(configTypeOptions)
            this.setConfigMode(configDefinition.config_mode as ConfigMode)

            if (id !== undefined) {
                this.setId(id)

                const response =
                    await FeaturesAdminService.getV2AdminFeatureConfig({
                        configId: id,
                    })

                this.form.init({
                    access_group_id:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    access_type: response?.access_type ?? "NONE",
                    feature_config_id: response?.feature_config_id,
                    name: response?.name ?? "",
                    segment_ids: response?.segment_ids ?? [],
                    configs: response?.config ?? {},
                    config_type:
                        response?.config !== undefined
                            ? Object.keys(response.config).map((name) => ({
                                  id: name,
                                  name,
                              }))
                            : [],
                })

                if (response?.segment_ids !== undefined) {
                    this.setSegmentIDs(response.segment_ids)
                }
                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )
                if (response?.access_type !== undefined) {
                    this.setAccessType(response?.access_type)
                }
            }
        } catch (error) {
            reportError(t`global.load-data-fail`, error)
            this.setAccessType("READ")
        }
    }

    async update(id: string) {
        const { data } = this.form

        const config = data.config_type.reduce((acc, configType) => {
            acc[configType.id] = data.configs[configType.id] ?? {}
            return acc
        }, {} as config_DynamicConfigMap)

        await FeaturesAdminService.putV2AdminFeatureConfig({
            configId: Number(id),
            request: {
                name: data.name,
                access_group_id: data.access_group_id,
                config,
            },
        })

        await FeaturesAdminService.putV2AdminFeatureConfigPublish({
            configId: id,
            request: {
                published_in: this._segmentIds,
            },
        })
    }

    async create() {
        const { data } = this.form

        const config = data.config_type.reduce((acc, configType) => {
            acc[configType.id] = data.configs[configType.id] ?? {}
            return acc
        }, {} as config_DynamicConfigMap)

        const response = await FeaturesAdminService.postV2AdminFeatureConfig({
            request: {
                name: data.name,
                access_group_id: data.access_group_id,
                config,
            },
        })

        if (response.feature_config_id !== undefined) {
            await FeaturesAdminService.putV2AdminFeatureConfigPublish({
                configId: `${response.feature_config_id}`,
                request: {
                    published_in: this._segmentIds,
                },
            })
        }
    }

    @loads(() => FeatureConfigBrandingStore.LoadingKeys.submit)
    async submit() {
        const { id } = this
        try {
            await this.form.catchErrors(async () => {
                if (id !== null) {
                    await this.update(id)
                } else {
                    await this.create()
                }

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "feature-config-branding",
                        action: id !== null ? "update" : "create",
                    },
                })
            })
        } catch (error) {
            reportError(t`global.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    async handleSubmit(
        validateConfigurableDynamicFields: () => boolean,
        getConfigsApiPayload: () => config_DynamicConfigMap | undefined,
    ) {
        this.setIsLoading(true)

        this.form.validateRequiredFields([
            { field: "name" },
            {
                field: "config_type",
                validate: (val) =>
                    Array.isArray(val) ? Boolean(val.length) : false,
            },
        ])

        const configurableDynamicFieldsHasError =
            validateConfigurableDynamicFields() === false

        if (
            this.form.hasErrors() ||
            configurableDynamicFieldsHasError === true
        ) {
            this.setIsLoading(false)
            return false
        }

        // update form.configs with latest values
        this.form.set("configs", getConfigsApiPayload() ?? {})

        trackModuleEvent("Feature Config Branding | Save", {
            [MixpanelProperties.ItemName]: this.form.data.name,
            [MixpanelProperties.AccessGroupID]: this.form.data.access_group_id,
        })

        await this.submit()
        return true
    }
    //#endregion
}
