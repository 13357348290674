import { makeAutoObservable } from "mobx"
import React from "react"

import {
    config_ConfigDefinition,
    config_ConfigDefinitionItem,
    config_DynamicConfigMap,
} from "src/api"
import { loads } from "src/channel/utils"
import { FIELD_TYPE_TEXT_LIST_LENGTH } from "src/components/ConfigurableDynamicFormFields/constants"
import { getSectionFieldName } from "src/components/ConfigurableDynamicFormFields/helpers/fieldNameHelpers"
import { ILocalConfigDefinitionItem } from "src/components/ConfigurableDynamicFormFields/types/configurable-fields"
import { DEFAULT_LANGUAGE } from "src/config"
import { persistFile } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { IFormFieldValidation } from "src/types/form-fields/validation"
import { IMultiLanguageTextFieldValue } from "src/types/modal-text-field"

export class ConfigurableDynamicFormFieldsStore {
    static Context =
        React.createContext<ConfigurableDynamicFormFieldsStore | null>(null)

    static LoadingKeys = createLoadingKeys("init", "submit")

    isLoading = false

    private _configurableFields: ILocalConfigDefinitionItem[] = []
    private _configs: config_DynamicConfigMap = {}
    private _isReadOnly: boolean = false
    private _location?: IGenericAutoCompleteTextFieldValue[] = undefined

    form = new FormFields<Record<string, unknown>>({})

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get configurableFields(): ILocalConfigDefinitionItem[] {
        return this._configurableFields
    }

    get configs(): config_DynamicConfigMap {
        return this._configs
    }

    get isReadOnly(): boolean {
        return this._isReadOnly
    }

    get location(): IGenericAutoCompleteTextFieldValue[] | undefined {
        return this._location
    }
    //#endregion

    //#region setters
    setConfigs(configs: config_DynamicConfigMap) {
        this._configs = configs
    }

    setConfigurableFields(configurableFields: config_ConfigDefinitionItem[]) {
        // filter configurableFields to be shown based on location filters
        const filteredConfigurableFields =
            this.location !== undefined
                ? configurableFields.filter((configurableField) =>
                      this.location?.find(
                          ({ name }) => name === configurableField.name,
                      ),
                  )
                : configurableFields

        // add length property to text_list field type
        const configurableFieldsWithLengthProperty: ILocalConfigDefinitionItem[] =
            filteredConfigurableFields.map((configurableField) => ({
                ...configurableField,
                configurable_fields:
                    configurableField.configurable_fields?.map((field) =>
                        field.type === "text_list"
                            ? { ...field, length: FIELD_TYPE_TEXT_LIST_LENGTH }
                            : field,
                    ) ?? [],
            }))

        this._configurableFields = configurableFieldsWithLengthProperty
    }

    setIsReadOnly(value: boolean) {
        this._isReadOnly = value
    }

    setIsLoading(value: boolean) {
        this.isLoading = value
    }

    setLocation(value?: IGenericAutoCompleteTextFieldValue[]) {
        this._location = value
    }
    //#endregion

    //#region operations
    @loads(() => ConfigurableDynamicFormFieldsStore.LoadingKeys.init)
    init(
        configDefinition: config_ConfigDefinition,
        configs: config_DynamicConfigMap,
        isReadOnly: boolean,
        location?: IGenericAutoCompleteTextFieldValue[],
    ) {
        this.setIsReadOnly(isReadOnly)
        this.setLocation(location)
        this.setConfigs(configs)
        this.setConfigurableFields(configDefinition.definition ?? [])

        this.form.init(this.getInitializedFormFields())
    }

    @loads(() => ConfigurableDynamicFormFieldsStore.LoadingKeys.init)
    updateLocationAndRelatedFields(
        location?: IGenericAutoCompleteTextFieldValue[],
        configurableFields?: config_ConfigDefinitionItem[],
    ) {
        this.setLocation(location)
        this.setConfigurableFields(configurableFields ?? [])

        this.form.init(this.getInitializedFormFields(true))
    }
    //#endregion

    //#region helpers
    getInitializedFormFields = (isUpdate: boolean = false) => {
        return this.configurableFields.reduce<Record<string, unknown>>(
            (acc, configurableField) => {
                configurableField.configurable_fields?.forEach((field) => {
                    const sectionName = configurableField.name ?? ""
                    const fieldName = field.name ?? ""
                    const fieldLength = field.length
                    const initialValue =
                        this.configs?.[sectionName]?.[fieldName] ?? undefined
                    // fields value can also be an array of data, incase there's field.length attribute
                    if (fieldLength !== undefined) {
                        for (let i = 0; i < fieldLength; i++) {
                            const formFieldKey = getSectionFieldName(
                                sectionName,
                                fieldName,
                                i,
                            )
                            acc[formFieldKey] = isUpdate
                                ? this.form.get(formFieldKey)
                                : initialValue?.[i] // initial value would be an array in this case
                        }
                    } else {
                        const formFieldKey = getSectionFieldName(
                            sectionName,
                            fieldName,
                        )
                        acc[formFieldKey] = isUpdate
                            ? this.form.get(formFieldKey)
                            : initialValue
                    }
                })

                return acc
            },
            {},
        )
    }

    getFieldsToValidate = () => {
        if (Object.keys(this.configurableFields).length === 0) return

        const customValidation = (value: unknown) => {
            const fieldValue = value as IMultiLanguageTextFieldValue
            return Boolean(fieldValue?.[DEFAULT_LANGUAGE])
        }

        const validations: IFormFieldValidation<Record<string, unknown>>[] = []
        this.configurableFields.forEach((configurableField) =>
            configurableField.configurable_fields?.forEach((field) => {
                if (!Boolean(field.required)) return

                const fieldLength = field.length
                const validationPayload = (index?: number) => ({
                    field: getSectionFieldName(
                        configurableField.name,
                        field.name,
                        index,
                    ),
                    validate:
                        field.localised === true ? customValidation : undefined,
                })

                if (fieldLength !== undefined) {
                    for (let i = 0; i < fieldLength; i++) {
                        validations.push(validationPayload(i))
                    }
                } else {
                    validations.push(validationPayload())
                }
            }),
        )

        return validations
    }

    handleValidation = () => {
        this.form.validateRequiredFields(this.getFieldsToValidate() ?? [])

        if (this.form.hasErrors() === true) {
            this.setIsLoading(false)
            return false
        }
        return true
    }

    getConfigsApiPayload = () => {
        if (Object.keys(this.configurableFields).length === 0) return

        const config: config_DynamicConfigMap = {}
        const setValueAsArrayFromFormFields = (
            sectionName: string,
            fieldName: string,
            fieldLength: number,
        ) => {
            const value = []
            for (let i = 0; i < fieldLength; i++) {
                const formFieldKey = getSectionFieldName(
                    sectionName,
                    fieldName,
                    i,
                )
                value.push(this.form.get(formFieldKey))
            }
            config[sectionName][fieldName] = value
        }

        this.configurableFields.forEach((configurableFields) => {
            const sectionName = configurableFields.name ?? ""
            config[sectionName] = {}

            configurableFields.configurable_fields?.forEach((field) => {
                const formFieldKey = getSectionFieldName(
                    sectionName,
                    field.name,
                )

                field.length !== undefined
                    ? setValueAsArrayFromFormFields(
                          sectionName,
                          field.name ?? "",
                          field.length,
                      )
                    : (config[sectionName][field.name ?? ""] =
                          this.form.get(formFieldKey))
            })
        })

        return config
    }

    handleIconChange = async (images: IFile[], fieldName: string) => {
        const [icon] = images
        if (icon === undefined || Object.keys(icon).length === 0) return

        const image = await persistFile(icon as ILocalFile, "image")

        if (image.url !== undefined) {
            this.form.set(fieldName, image.url)
        }
    }
    //#endregion
}
