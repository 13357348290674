import React from "react"
import { makeAutoObservable } from "mobx"

import { content_ContentItemConfigType, ContentAdminService } from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Pagination } from "src/lib/pagination"
import { loads } from "src/channel/utils"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import {
    IContentItems,
    IContentItemsMultiSelectOption,
} from "src/views/content-items/types/content-items"

export class ContentItemsStore implements IDisposable {
    static Context = React.createContext<ContentItemsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    _configTypeFilterSelected: IContentItemsMultiSelectOption[] = []
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []
    _priorityColumnName: content_ContentItemConfigType[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose(): void {
        this.repositoryUpdatesListenerDisposer?.()
    }

    contentItems = new Pagination(
        async (query) => {
            const configType: content_ContentItemConfigType[] =
                this._configTypeFilterSelected.map(
                    (configType) => configType.id,
                )
            const response =
                await ContentAdminService.postV1AdminContentItemList({
                    request: {
                        page_number: query.page,
                        page_size: query.pageSize,
                        filter: query.advanceQuery,
                        configType:
                            configType.length > 0 ? configType : undefined,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })
            const items: IContentItems[] =
                response?.content_items?.map((item) => ({
                    id: item.content_item_id ?? 0,
                    icon: item.icon_url ?? "",
                    internal_name: item.internal_name ?? "",
                    entity: item.entity ?? "",
                    location: item?.config_type ?? [],
                    priority_main_navigation: item.priority_main_navigation,
                    priority_move_in_guide: item.priority_move_in_guide,
                    priority_overview: item.priority_overview,
                    priority_spotlight: item.priority_spotlight,
                    priority_wizard: item.priority_move_in_wizard,
                    published_to:
                        item.segment_ids?.length != null
                            ? `${item.segment_ids?.length} segments`
                            : "",
                    access_type: item?.access_type ?? "WRITE",
                })) ?? []

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: ContentItemsStore.LoadingKeys.loading },
    )

    setConfigTypeFilterSelected = async (
        value: IContentItemsMultiSelectOption[] = [],
    ) => {
        this._configTypeFilterSelected = value
        this.priorityColumnSelector(value)
        await this.contentItems.reload()
    }

    refresh = async () => {
        await this.contentItems.loadInitialPage()
    }

    get segments() {
        return this._segments
    }

    get configTypeFilterSelectedValue() {
        return this._configTypeFilterSelected
    }

    get priorityColumnName() {
        return this._priorityColumnName
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.contentItems.loadInitialPage()
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private priorityColumnSelector = (
        location: IContentItemsMultiSelectOption[],
    ) => {
        this._priorityColumnName = location.map((location) => location.id)
    }

    @loads(() => ContentItemsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.contentItems.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.contentItems.loadAdvanceQuery(advanceQuery)
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "content-items"
                ) {
                    await this.contentItems.reload()
                }
            },
        )
    }

    deleteItem = async (id: number) => {
        try {
            await ContentAdminService.deleteV1AdminContentItem({
                contentItemId: id,
            })
        } catch (error) {
            reportError("Failed to delete item")
        } finally {
            await this.contentItems.reload()
        }
    }
}
